import React from 'react';
import './AboutHeroSection.css';
import { Link } from 'gatsby';

const AboutHeroSection = () => {
  return (
    <section className="about-hero-section" aria-labelledby="about-hero-title" aria-describedby="about-hero-subtitle">
      <div className="about-hero-content">
        <h1 className="about-hero-title" id="about-hero-title">Conheça o Prontuário Fácil</h1>
        <p className="about-hero-subtitle" id="about-hero-subtitle">Transformando a gestão de prontuários médicos com tecnologia e inovação.</p>
        <Link to="/novo-usuario" id="cta-principal" className="about-hero-cta">Saiba Mais</Link>
      </div>
    </section>
  );
};

export default AboutHeroSection;
