import React from 'react';
import './AboutTestimonialsSection.css';

const AboutTestimonialsSection = () => {
    return (
        <section className="testimonials-section" aria-labelledby="testimonials-title">
            <div className="testimonials-content">
                <h2 className="testimonials-title" id="testimonials-title">O que os Usuários Dizem Sobre Nós</h2>
                <div className="testimonials-container">
                    <div className="testimonial-item">
                        <blockquote className="testimonial-text">
                            <p>"O Prontuário Fácil revolucionou a forma como gerenciamos nossos registros médicos. A eficiência e a facilidade de uso são incomparáveis!"</p>
                        </blockquote>
                        <h3 className="testimonial-author">- Dr. Ana Beatriz, Pediatra</h3>
                    </div>
                    <div className="testimonial-item">
                        <blockquote className="testimonial-text">
                            <p>"Desde que começamos a usar o Prontuário Fácil, notamos uma melhoria significativa na precisão dos nossos dados e na satisfação dos pacientes."</p>
                        </blockquote>
                        <h3 className="testimonial-author">- Dr. Carlos Mendes, Médico de Família</h3>
                    </div>
                    <div className="testimonial-item">
                        <blockquote className="testimonial-text">
                            <p>"A capacidade de acessar informações de qualquer lugar foi um divisor de águas para nossa equipe, especialmente em situações de emergência."</p>
                        </blockquote>
                        <h3 className="testimonial-author">- Dra. Sofia Correia, Emergencista</h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutTestimonialsSection;
