import React, { useState } from 'react';
import './AboutFAQSection.css';
import { FiPlus, FiMinus } from 'react-icons/fi';

const AboutFAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="faq-section" aria-labelledby="faq-title">
            <div className="faq-content">
                <h2 className="faq-title" id="faq-title">Perguntas Frequentes</h2>
                <div className="faq-container">
                    {[{
                        question: "Posso acessar o Prontuário Fácil em dispositivos móveis?",
                        answer: "Sim, nosso sistema é completamente responsivo e pode ser acessado em qualquer dispositivo, incluindo smartphones e tablets, garantindo flexibilidade total no acesso às informações de saúde."
                    }, {
                        question: "Como posso integrar o Prontuário Fácil com outros sistemas hospitalares?",
                        answer: "O Prontuário Fácil foi desenvolvido para ser compatível com diversos sistemas de informação hospitalar (HIS), utilizando APIs que facilitam a integração segura e eficiente."
                    }, {
                        question: "Existem funcionalidades de backup automático dos dados?",
                        answer: "Sim, o Prontuário Fácil realiza backups automáticos diários para garantir que todas as informações estejam seguras e possam ser recuperadas em caso de qualquer eventualidade."
                    }, {
                        question: "Qual é o suporte técnico oferecido pelo Prontuário Fácil?",
                        answer: "Oferecemos suporte técnico 24/7 por meio de telefone, e-mail e chat ao vivo para garantir que qualquer dúvida ou problema seja resolvido com rapidez e eficácia."
                    }].map((item, index) => (
                        <div
                            key={index}
                            className={`faq-item ${activeIndex === index + 1 ? 'open' : ''}`}
                            onClick={() => toggleFAQ(index + 1)}
                            onKeyPress={(e) => (e.key === 'Enter' || e.key === ' ') && toggleFAQ(index + 1)}
                            tabIndex={0}
                            role="button"
                            aria-expanded={activeIndex === index + 1}
                            aria-controls={`faq-answer-${index + 1}`}
                        >
                            <div className="faq-question">
                                <h3 id={`faq-question-${index + 1}`}>{item.question}</h3>
                                <span>{activeIndex === index + 1 ? <FiMinus /> : <FiPlus />}</span>
                            </div>
                            {activeIndex === index + 1 && (
                                <div className="faq-answer" id={`faq-answer-${index + 1}`} role="region" aria-labelledby={`faq-question-${index + 1}`}>
                                    <p>{item.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AboutFAQSection;
