import React from 'react';
import './AboutContactSection.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const AboutContactSection = () => {
    return (
        <section className="contact-section" aria-labelledby="contact-title">
            <div className="contact-content">
                <h2 className="contact-title" id="contact-title">Entre em Contato Conosco</h2>
                <p>Tem alguma dúvida ou precisa de mais informações? Fale conosco diretamente através dos seguintes meios:</p>

                <div className="contact-methods">
                    <div className="contact-method" id="contact-phone">
                        <FaPhone className="contact-icon" aria-hidden="true" />
                        <h3>Telefone</h3>
                        <p>(11) 1234-5678</p>
                    </div>
                    <div className="contact-method" id="contact-email">
                        <FaEnvelope className="contact-icon" aria-hidden="true" />
                        <h3>Email</h3>
                        <p>contato@prontuariofacil.com.br</p>
                    </div>
                    <div className="contact-method" id="contact-address">
                        <FaMapMarkerAlt className="contact-icon" aria-hidden="true" />
                        <h3>Endereço</h3>
                        <p>Avenida Brasil, 500 - São Paulo, SP</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutContactSection;
