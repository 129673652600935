import React from 'react';
import './HistorySection.css';

const HistorySection = () => {
    return (
        <section className="history-section" aria-labelledby="history-title">
            <div className="history-content">
                <h2 id="history-title" className="history-title">Nossa Trajetória</h2>
                <p className="history-intro">
                    Desde a nossa fundação, percorremos um longo caminho, sempre focados na inovação e na melhoria do gerenciamento de saúde.
                </p>
                <ul className="timeline" aria-label="Linha do tempo da trajetória da empresa">
                    <li className="timeline-event">
                        <h3>2010 - O Início</h3>
                        <p>Lançamos nossa primeira versão do sistema, focada em pequenas clínicas.</p>
                    </li>
                    <li className="timeline-event">
                        <h3>2015 - Expansão de Recursos</h3>
                        <p>Introduzimos funcionalidades avançadas como inteligência artificial para prever diagnósticos.</p>
                    </li>
                    <li className="timeline-event">
                        <h3>2020 - Liderança no Mercado</h3>
                        <p>Nos consolidamos como líderes de mercado com mais de 5.000 clientes ativos em todo o país.</p>
                    </li>
                    <li className="timeline-event">
                        <h3>2023 - Parcerias Internacionais</h3>
                        <p>Expandimos nossas operações com parcerias na Europa e na América do Norte.</p>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default HistorySection;
