import React from 'react';
import './MissionVisionSection.css';

const MissionVisionSection = () => {
    return (
        <section className="mission-vision-section" aria-labelledby="mission-vision-title">
            <div className="mission-vision-content">
                <h2 id="mission-vision-title" className="mission-vision-title">Nossa Missão e Visão</h2>
                <p className="mission-description">
                    A missão do Prontuário Fácil é empoderar profissionais de saúde através da tecnologia, proporcionando um sistema de prontuários eletrônicos intuitivo e completo que facilita o acesso a informações críticas, melhora a qualidade do atendimento ao paciente e otimiza o fluxo de trabalho.
                </p>
                <p className="vision-description">
                    Nossa visão é ser a plataforma líder em gestão de prontuários médicos no Brasil, reconhecida pela inovação, confiabilidade e comprometimento com a melhoria contínua da saúde.
                </p>
            </div>
        </section>
    );
};

export default MissionVisionSection;
