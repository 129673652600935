import React from 'react';
import Seo from '../components/Seo';
import './sobre.css';
import AboutHeroSection from '../components/AboutHeroSection/AboutHeroSection';
import MissionVisionSection from '../components/MissionVisionSection/MissionVisionSection';
import HistorySection from '../components/HistorySection/HistorySection';
import InnovationSection from '../components/InnovationSection/InnovationSection';
import AboutTestimonialsSection from '../components/AboutTestimonialsSection/AboutTestimonialsSection';
import SocialResponsibilitySection from '../components/SocialResponsibilitySection/SocialResponsibilitySection';
import PartnershipsSection from '../components/PartnershipsSection/PartnershipsSection';
import CallToActionSection from '../components/CallToActionSection/CallToActionSection';
import AboutContactSection from '../components/AboutContactSection/AboutContactSection';
import AboutFAQSection from '../components/AboutFAQSection/AboutFAQSection';
import Layout from '../components/layout';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';

const About = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/sobre', label: 'Sobre' }
  ];

  return (
    <Layout>
      <div className="about-container">
        <Seo
          title="Sobre o Prontuário Fácil: Software Médico para Clínicas e Consultórios"
          description="Conheça a história, missão e valores do Prontuário Fácil, o software médico que simplifica a gestão de clínicas e consultórios. Descubra como estamos inovando na área da saúde."
          keywords="prontuário fácil, sobre nós, software médico, prontuário eletrônico, gestão de clínicas, missão, visão, valores, história, inovação, saúde"
        />
        <Breadcrumbs crumbs={crumbs} />
        <main className="content">
          <AboutHeroSection />
          <MissionVisionSection />
          <HistorySection />
          <InnovationSection />
          <AboutTestimonialsSection />
          <SocialResponsibilitySection />
          <PartnershipsSection />
          <CallToActionSection />
          <AboutContactSection />
          <AboutFAQSection />
        </main>
      </div>
    </Layout>
  );
};

export default About;