import React from "react"
import "./SocialResponsibilitySection.css"

const SocialResponsibilitySection = () => {
  return (
    <section
      className="social-responsibility-section"
      aria-labelledby="social-responsibility-title"
    >
      <div className="social-responsibility-content">
        <h2
          id="social-responsibility-title"
          className="social-responsibility-title"
        >
          Nossa Responsabilidade Social
        </h2>
        <p className="social-responsibility-description">
          No Prontuário Fácil, estamos comprometidos com a inovação tecnológica
          e a promoção da saúde e bem-estar em todas as comunidades onde
          operamos. Conheça nossas iniciativas que impactam positivamente a
          saúde pública e o meio ambiente.
        </p>
        <div
          className="initiatives-list"
          role="list"
          aria-labelledby="initiatives-list-title"
        >
          <div
            className="initiative-item"
            role="listitem"
            aria-labelledby="initiative-health"
          >
            <h3 id="initiative-health">Programas de Saúde Comunitária</h3>
            <p>
              Desenvolvemos e apoiamos programas que visam melhorar a
              acessibilidade aos cuidados médicos em comunidades carentes.
            </p>
          </div>
          <div
            className="initiative-item"
            role="listitem"
            aria-labelledby="initiative-sustainability"
          >
            <h3 id="initiative-sustainability">Sustentabilidade e Saúde</h3>
            <p>
              Implementamos práticas sustentáveis que minimizam nosso impacto
              ambiental enquanto maximizam a eficácia do cuidado ao paciente.
            </p>
          </div>
          <div
            className="initiative-item"
            role="listitem"
            aria-labelledby="initiative-education"
          >
            <h3 id="initiative-education">Educação e Treinamento em Saúde</h3>
            <p>
              Oferecemos treinamentos e recursos educacionais para profissionais
              de saúde, garantindo serviços de alta qualidade e informados.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SocialResponsibilitySection
