import React, { useState, useEffect } from "react"
import "./PartnershipsSection.css"
import grupoFleury from "../../assets/images/parceiros/grupo-fleury.svg"
import unimedSantos from "../../assets/images/parceiros/unimed-santos.svg"
import bradescoSaude from "../../assets/images/parceiros/bradesco-saude.svg"
import apm from "../../assets/images/parceiros/apm.svg"
import SociedadeReumatologia from "../../assets/images/parceiros/sociedade-brasileira-reumatologia.svg"

const brands = [
  {
    id: "logo-grupo-fleury",
    src: grupoFleury,
    alt: "Grupo Fleury - Prontuário Fácil",
  },
  {
    id: "logo-unimed-santos",
    src: unimedSantos,
    alt: "Unimed Santos - Prontuário Fácil",
  },
  {
    id: "logo-bradesco-saude",
    src: bradescoSaude,
    alt: "Bradesco Saúde - Prontuário Fácil",
  },
  { id: "logo-apm", src: apm, alt: "APM - Prontuário Fácil" },
  {
    id: "logo-sociedade-reumatologia",
    src: SociedadeReumatologia,
    alt: "Sociedade Brasileira de Reumatologia - Prontuário Fácil",
  },
]

const PartnershipsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerView = 5 // Definir o número de itens visíveis ao mesmo tempo (para desktop)

  const nextSlide = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === brands.length - itemsPerView ? 0 : prevIndex + 1
    )
  }

  useEffect(() => {
    const interval = setInterval(nextSlide, 4000) // Avança a cada 4 segundos
    return () => clearInterval(interval) // Limpa o intervalo quando o componente desmonta
  }, [])

  return (
    <section
      id="partnerships-section"
      className="partnerships-section"
      aria-labelledby="partnerships-heading"
    >
      <div className="partnerships-content">
        <h2 id="partnerships-heading" className="partnerships-title">
          Nossas Parcerias Estratégicas
        </h2>
        <p id="partnerships-description" className="partnerships-description">
          Estamos orgulhosos de colaborar com líderes da indústria e
          instituições acadêmicas para inovar e melhorar continuamente os
          cuidados de saúde.
        </p>
        <div className="carousel">
          <div
            className="carousel-wrapper"
            style={{
              transform: `translateX(-${
                (currentIndex / brands.length) * 100
              }%)`,
              width: `${(brands.length / itemsPerView) * 100}%`,
            }}
          >
            {brands.map(brand => (
              <div key={brand.id} className="carousel-slide">
                <img
                  id={brand.id}
                  src={brand.src}
                  alt={brand.alt}
                  className="carousel-item"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PartnershipsSection
