import React from 'react';
import './InnovationSection.css';

const InnovationSection = () => {
    return (
        <section className="innovation-section" aria-labelledby="innovation-title">
            <div className="innovation-content">
                <h2 id="innovation-title" className="innovation-title">Inovação e Diferenciais</h2>
                <p className="innovation-description">
                    No Prontuário Fácil, lideramos com inovações que transformam o gerenciamento de saúde. Conheça as tecnologias que nos colocam à frente.
                </p>
                <div className="features-grid">
                    <div className="feature-item">
                        <h3 id="feature-ai">Inteligência Artificial</h3>
                        <p>Utilizamos AI para prever tendências de saúde e auxiliar diagnósticos, aumentando a precisão médica e reduzindo erros.</p>
                    </div>
                    <div className="feature-item">
                        <h3 id="feature-remote-access">Acesso Remoto</h3>
                        <p>Nosso sistema permite acesso remoto seguro, garantindo que os médicos possam consultar dados críticos de qualquer lugar, a qualquer hora.</p>
                    </div>
                    <div className="feature-item">
                        <h3 id="feature-data-integration">Integração de Dados</h3>
                        <p>Integramos dados de múltiplas fontes para oferecer uma visão completa do paciente, simplificando o fluxo de trabalho médico.</p>
                    </div>
                    <div className="feature-item">
                        <h3 id="feature-updates">Atualizações Constantes</h3>
                        <p>Estamos sempre atualizando nosso sistema com as mais recentes regulamentações e tecnologias de saúde para manter você atualizado.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InnovationSection;
